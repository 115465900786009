import * as React from "react";
import { Link, graphql } from "gatsby";

import Bio from "../components/bio";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";
import Avatar from "../images/avatar_reflect.inline.svg";

const SectionTitle = styled.h2`
  margin-top: var(--spacing-12);
  font-size: var(--fontSize-1);
  font-weight: var(--fontWeight-bold);
  color: var(--color-primary);
  text-transform: uppercase;
`;

const IntroText = styled.p`
  font-family: var(--font-heading);
  text-align: ${props => (props.primary ? "center" : "left")};
  margin-top: var(--spacing-12);
  margin-bottom: var(--spacing-6);
  line-height: var(--lineHeight-tight);
  font-weight: var(--fontWeight-extrabold);
  color: var(--color-heading);
  font-size: ${props =>
    props.primary ? "var(--fontSize-5)" : "var(--fontSize-3)"};
`;

const SiteIntro = () => (
  <div>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Avatar
        style={{
          marginTop: "var(--spacing-6)",
          width: "100px",
          height: "100px",
          minWidth: "100px",
        }}
      />
    </div>
    <IntroText primary>Oh, hey there!</IntroText>
    <IntroText primary>
      I'm Anna. I design and build meaningful digital experiences with a
      sprinkle of whimsy.
    </IntroText>
    <IntroText>
      I love making sites and apps that are memorable and truly joyful to use.
      My goal with this blog is to document and share the things I learn with
      every new project.
    </IntroText>
    <p>
      Need to get in touch with me? Reach out on{" "}
      <a href={`https://twitter.com/rossettiquette`}>Twitter</a> or by{" "}
      <a
        href="mailto:hi@annarossetti.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        email
      </a>
      .
    </p>
  </div>
);

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const posts = data.allMdx.nodes;

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo />
        <Bio />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    );
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo pathname={location.pathname} />
      <SiteIntro />
      <SectionTitle>Recently published:</SectionTitle>
      <ol style={{ listStyle: `none` }}>
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug;

          return (
            <li key={post.fields.slug}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <h2>
                    <Link to={post.fields.slug} itemProp="url">
                      <span itemProp="headline">{title}</span>
                    </Link>
                  </h2>
                  <small>{post.frontmatter.date}</small>
                </header>
                <section>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description || post.excerpt,
                    }}
                    itemProp="description"
                  />
                </section>
              </article>
            </li>
          );
        })}
      </ol>
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`;
