/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `);

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author;
  const social = data.site.siteMetadata?.social;

  return (
    <div className="bio">
      <StaticImage
        style={{ marginTop: "auto", marginBottom: "auto", marginRight: "20px" }}
        className="bio-avatar"
        layout="fixed"
        formats={["auto", "webp", "avif"]}
        src="../images/profilepic.png"
        width={50}
        height={50}
        quality={95}
        alt="Profile picture"
      />
      {author?.name && (
        <p>
          Written by <strong>{author.name}</strong>, {author?.summary || null}
          {` `}Want to get in touch? Reach out on{` `}
          <a href={`https://twitter.com/${social?.twitter || ``}`}>
            Twitter
          </a>{" "}
          or by{" "}
          <a
            href="mailto:hi@annarossetti.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            email
          </a>
          .
        </p>
      )}
    </div>
  );
};

export default Bio;
